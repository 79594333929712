import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from '@dougs/core/state';
import { PresenceMember } from '../interfaces/presence-member.interface';

interface PresenceMemberState {
  members: PresenceMember[];
}

@Injectable({
  providedIn: 'root',
})
export class PresenceMemberStateService extends StateService<PresenceMemberState> {
  presenceMembers$: Observable<PresenceMember[]> = this.select<PresenceMember[]>((state) => state.members);

  constructor() {
    super();
  }

  modifyMembers(members: PresenceMember[]): void {
    this.setState({
      members,
    });
  }

  addMember(member: PresenceMember): void {
    if (!this.state.members.some((memberIterated) => memberIterated.id === member.id)) {
      this.setState({
        members: [...this.state.members, member],
      });
    }
  }

  removeMember(member: PresenceMember): void {
    this.setState({
      members: this.state.members.filter((memberFound: PresenceMember) => Number(memberFound.id) !== Number(member.id)),
    });
  }
}
