import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from '@dougs/core/state';
import { Task } from '@dougs/task/dto';
import { CompanyChanged } from '../dto/company-changed.dto';

interface CustomerTasksState {
  customerTasks: CompanyChanged<Task>;
}

@Injectable({
  providedIn: 'root',
})
export class CustomerTasksStateService extends StateService<CustomerTasksState> {
  customerTasksCreated$: Observable<CompanyChanged<Task>> = this.select<CompanyChanged<Task>>(
    (state) => state.customerTasks,
  );

  constructor() {
    super();
  }

  createCustomerTasks(customerTasks: CompanyChanged): void {
    this.setState({
      customerTasks,
    });
  }
}
