import { Inject, Injectable } from '@angular/core';
import Pusher, { Channel, Members } from 'pusher-js';
import { FlashMessagesService, LegacyPushMessage } from '@dougs/ds';
import { CompanyChanged } from '../dto/company-changed.dto';
import { PresenceMember } from '../interfaces/presence-member.interface';
import { SocketSettings } from '../interfaces/socket-settings.interface';
import { CompanyChangedStateService } from '../states/company-changed.state';
import { CustomerTasksStateService } from '../states/customer-tasks.state';
import { PresenceMemberStateService } from '../states/presence-member.state';
import { SOCKET_SETTINGS_TOKEN } from '../tokens/socket-settings.token';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  pusher!: Pusher;

  companyChannels: Channel[] = [];
  userChannels: Channel[] = [];

  constructor(
    @Inject(SOCKET_SETTINGS_TOKEN) private readonly settings: SocketSettings,
    private readonly presenceMemberStateService: PresenceMemberStateService,
    private readonly companyChangedStateService: CompanyChangedStateService,
    private readonly customerTasksStateService: CustomerTasksStateService,
    private readonly flashMessagesService: FlashMessagesService,
  ) {
    this.pusher = new Pusher(this.settings.apiKey, {
      cluster: this.settings.cluster,
      authEndpoint: this.settings.authEndpoint,
    });
  }

  bindCompanyById(companyId: number): void {
    this.clearChannels(this.companyChannels);
    this.companyChannels = [];
    this.listenPresenceCompanyChanged(companyId);
    this.listenCompanyChanged(companyId);
    this.listenCustomerTasksChanged(companyId);
  }

  bindUserById(userId: number): void {
    this.clearChannels(this.userChannels);
    this.userChannels = [];
    this.listenStandardPushes(userId);
  }

  private listenStandardPushes(userId: number): void {
    const channelName = `private-push-${userId}`;
    const userChannel: Channel = this.listenChannel(channelName, 'pushMessage', (data: LegacyPushMessage) => {
      this.flashMessagesService.show(data.content, { type: data.type, ...data.options });
    });

    this.userChannels.push(userChannel);
  }

  private listenCompanyChanged(companyId: number): void {
    const channelName = `private-company-${companyId}`;
    const companyChannel: Channel = this.listenChannel(channelName, 'modelChanged', (e: CompanyChanged) => {
      this.companyChangedStateService.modifyCompanyChanged(e);
    });

    this.companyChannels.push(companyChannel);
  }

  private listenCustomerTasksChanged(companyId: number): void {
    const channelName = `private-companyCustomerTasks-${companyId}`;
    const customerTasksChannel: Channel = this.listenChannel(channelName, 'modelChanged', (e: CompanyChanged) => {
      this.customerTasksStateService.createCustomerTasks(e);
    });

    this.companyChannels.push(customerTasksChannel);
  }

  private listenPresenceCompanyChanged(companyId: number): void {
    const channelName = `presence-company-${companyId}`;
    const event = 'pusher:subscription_succeeded';
    const eventAddMember = 'pusher:member_added';
    const eventRemoveMember = 'pusher:member_removed';

    const addMemberChannel: Channel = this.listenChannel(channelName, eventAddMember, (member: PresenceMember) => {
      this.presenceMemberStateService.addMember(member);
    });

    this.companyChannels.push(addMemberChannel);

    const removeMemberChannel: Channel = this.listenChannel(
      channelName,
      eventRemoveMember,
      (member: PresenceMember) => {
        this.presenceMemberStateService.removeMember(member);
      },
    );

    this.companyChannels.push(removeMemberChannel);

    const modifyMemberChannel: Channel = this.listenChannel(channelName, event, (members: Members) => {
      let presenceMembers: PresenceMember[] = [];
      members.each((member: PresenceMember) => {
        presenceMembers = [...presenceMembers, member];
      });

      this.presenceMemberStateService.modifyMembers(presenceMembers);
    });

    this.companyChannels.push(modifyMemberChannel);
  }

  private listenChannel(channelName: string, event: string, callback: (args: any) => void): Channel {
    const channel: Channel = this.pusher.subscribe(channelName);
    channel.bind(event, (members: Members) => {
      callback(members);
    });

    return channel;
  }

  private clearChannels(channels: Channel[]): void {
    for (const channel of channels) {
      channel.unsubscribe();
      channel.unbind_all();
    }
  }
}
