import { APP_INITIALIZER, Provider } from '@angular/core';
import { SOCKET_SETTINGS_TOKEN } from '../tokens/socket-settings.token';

export function socketInitializer() {
  return async () => true;
}

export const SOCKET_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: socketInitializer,
  deps: [SOCKET_SETTINGS_TOKEN],
};
