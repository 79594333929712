import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StateService } from '@dougs/core/state';
import { CompanyChanged } from '../dto/company-changed.dto';

interface CompanyChangedState {
  companyChanged: CompanyChanged;
}

@Injectable({
  providedIn: 'root',
})
export class CompanyChangedStateService extends StateService<CompanyChangedState> {
  companyChanged$: Observable<CompanyChanged> = this.select<CompanyChanged>((state) => state.companyChanged);

  constructor() {
    super();
  }

  modifyCompanyChanged(companyChanged: CompanyChanged): void {
    this.setState({
      companyChanged,
    });
  }
}
