import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { SOCKET_INITIALIZER_PROVIDER } from './initializers/socket.initializer';
import { SocketSettings } from './interfaces/socket-settings.interface';
import { SOCKET_SETTINGS_TOKEN } from './tokens/socket-settings.token';

export function provideEnvironmentSocket(configValue?: SocketSettings): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: SOCKET_SETTINGS_TOKEN,
      useValue: configValue,
    },
    SOCKET_INITIALIZER_PROVIDER,
  ]);
}
